///* You can add global styles to this file, and also import other style files */
//$gutter: 1rem; //for primeflex grid system
//
/////* PrimeNG */
@import 'node_modules/primeng/resources/primeng.min.css';
////// TODO valami global style-s csomagot csinalni es abba atrakni
////@import 'custom-primeflex';
@import 'node_modules/primeicons/primeicons.css';
//
///* Quill Text Editor for p-editor */
////@import "node_modules/quill/dist/quill.core.css";
////@import "node_modules/quill/dist/quill.snow.css";
//
///* Demos */
////@import "assets/demo/styles/flags/flags.css";
////@import "assets/demo/styles/badges.scss";
////@import "assets/demo/styles/code.scss";
//
////.layout-topbar {
////  position: relative !important;
////}
////
////.layout-content-wrapper {
////  padding-top: 0 !important;
////}
//

@layer primeng,  tailwind-utilities;
@layer primeng;
@layer tailwind-utilities {
  //@tailwind components;
  @tailwind utilities;
}

body {
  overflow-x: hidden;
}

.center-center {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

p {
  color: var(--text-color);
}
