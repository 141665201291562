.layout-sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 18rem;
  background: var(--menu-bg);
  border-right: var(--sidebar-border);
  box-shadow: var(--sidebar-shadow);
  display: flex;
  flex-direction: column;

  .sidebar-header {
    padding: 2.5rem 0 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .app-logo {
      .app-logo-normal {
        display: inline;
        width: 145px;
        height: auto;
      }

      .app-logo-small {
        width: 20px;
        height: auto;
        display: none;
      }
    }
  }

  .layout-menu-container {
    overflow: auto;
    flex: 1;
    padding-bottom: 2rem;
  }

  .layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
      > .layout-menuitem-root-text {
        font-size: 0.857rem;
        text-transform: uppercase;
        color: var(--root-menuitem-text-color);
        font-weight: 600;
        padding: 1rem 2rem 0.5rem 2rem;
        letter-spacing: 0.05rem;
        // Hide if empty
        &:empty {
          display: none;
        }
      }

      > a {
        display: none;
      }
    }

    a {
      user-select: none;

      &.active-menuitem {
        > .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    li.active-menuitem {
      > a {
        .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      a {
        display: flex;
        align-items: center;
        position: relative;
        outline: 0 none;
        color: var(--menuitem-text-color);
        cursor: pointer;
        padding: 0.75rem 2rem;
        transition:
          background-color var(--transition-duration),
          box-shadow var(--transition-duration);
        letter-spacing: 0.05rem;

        .layout-menuitem-icon {
          margin-right: 0.5rem;
          color: var(--menuitem-icon-color);
        }

        .layout-submenu-toggler {
          font-size: 75%;
          margin-left: auto;
          transition: transform var(--transition-duration);
        }

        &.active-route {
          font-weight: 600;
          color: var(--primary-color);
        }

        &:hover {
          background-color: var(--menuitem-hover-bg-color);
        }
      }

      ul {
        overflow: hidden;
        border-radius: var(--border-radius);
        // background-color: var(--submenu-bg);

        li {
          a {
            padding-left: 2.5rem;
          }

          li {
            a {
              padding-left: 3rem;
            }

            li {
              a {
                padding-left: 3.5rem;
              }

              li {
                a {
                  padding-left: 4rem;
                }

                li {
                  a {
                    padding-left: 5.5rem;
                  }

                  li {
                    a {
                      padding-left: 5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
