@font-face {
  font-family: 'cs-icon-font';
  src: url('cs-icon-font.eot?t=1739289206853'); /* IE9*/
  src:
    url('cs-icon-font.eot?t=1739289206853#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('cs-icon-font.woff2?t=1739289206853') format('woff2'),
    url('cs-icon-font.woff?t=1739289206853') format('woff'),
    url('cs-icon-font.ttf?t=1739289206853') format('truetype'),
    url('cs-icon-font.svg?t=1739289206853') format('svg');
}

[class^='cs-icon-font-'],
[class*=' cs-icon-font-'] {
  font-family: 'cs-icon-font' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-icon-font-mdi-account-circle:before {
  content: '\ea01';
}
.cs-icon-font-mdi-account-plus:before {
  content: '\ea02';
}
.cs-icon-font-mdi-account-supervisor:before {
  content: '\ea03';
}
.cs-icon-font-mdi-account-tie-hat:before {
  content: '\ea04';
}
.cs-icon-font-mdi-account:before {
  content: '\ea05';
}
.cs-icon-font-mdi-alert-circle-outline:before {
  content: '\ea06';
}
.cs-icon-font-mdi-alert:before {
  content: '\ea07';
}
.cs-icon-font-mdi-alpha-tbox-outline:before {
  content: '\ea08';
}
.cs-icon-font-mdi-arrow-down-circle:before {
  content: '\ea09';
}
.cs-icon-font-mdi-arrow-down:before {
  content: '\ea0a';
}
.cs-icon-font-mdi-arrow-left:before {
  content: '\ea0b';
}
.cs-icon-font-mdi-arrow-right:before {
  content: '\ea0c';
}
.cs-icon-font-mdi-arrow-up-circle:before {
  content: '\ea0d';
}
.cs-icon-font-mdi-arrow-up:before {
  content: '\ea0e';
}
.cs-icon-font-mdi-badge-account:before {
  content: '\ea0f';
}
.cs-icon-font-mdi-bell:before {
  content: '\ea10';
}
.cs-icon-font-mdi-calendar-range:before {
  content: '\ea11';
}
.cs-icon-font-mdi-calendar:before {
  content: '\ea12';
}
.cs-icon-font-mdi-camera:before {
  content: '\ea13';
}
.cs-icon-font-mdi-cancel:before {
  content: '\ea14';
}
.cs-icon-font-mdi-check-circle-outline:before {
  content: '\ea15';
}
.cs-icon-font-mdi-check-circle:before {
  content: '\ea16';
}
.cs-icon-font-mdi-check:before {
  content: '\ea17';
}
.cs-icon-font-mdi-clock-outline:before {
  content: '\ea18';
}
.cs-icon-font-mdi-close:before {
  content: '\ea19';
}
.cs-icon-font-mdi-cloud-upload-outline:before {
  content: '\ea1a';
}
.cs-icon-font-mdi-cog:before {
  content: '\ea1b';
}
.cs-icon-font-mdi-compass:before {
  content: '\ea1c';
}
.cs-icon-font-mdi-content-copy:before {
  content: '\ea1d';
}
.cs-icon-font-mdi-content-save-alert:before {
  content: '\ea1e';
}
.cs-icon-font-mdi-content-save-check:before {
  content: '\ea1f';
}
.cs-icon-font-mdi-content-save-edit:before {
  content: '\ea20';
}
.cs-icon-font-mdi-content-save:before {
  content: '\ea21';
}
.cs-icon-font-mdi-crown:before {
  content: '\ea22';
}
.cs-icon-font-mdi-delete:before {
  content: '\ea23';
}
.cs-icon-font-mdi-download-outline:before {
  content: '\ea24';
}
.cs-icon-font-mdi-email:before {
  content: '\ea25';
}
.cs-icon-font-mdi-eye:before {
  content: '\ea26';
}
.cs-icon-font-mdi-file-document-check-outline:before {
  content: '\ea27';
}
.cs-icon-font-mdi-file-eye-outline:before {
  content: '\ea28';
}
.cs-icon-font-mdi-file-import-outline:before {
  content: '\ea29';
}
.cs-icon-font-mdi-file-outline:before {
  content: '\ea2a';
}
.cs-icon-font-mdi-filter-outline:before {
  content: '\ea2b';
}
.cs-icon-font-mdi-filter:before {
  content: '\ea2c';
}
.cs-icon-font-mdi-flag:before {
  content: '\ea2d';
}
.cs-icon-font-mdi-folder-plus:before {
  content: '\ea2e';
}
.cs-icon-font-mdi-folder:before {
  content: '\ea2f';
}
.cs-icon-font-mdi-form-textbox:before {
  content: '\ea30';
}
.cs-icon-font-mdi-information:before {
  content: '\ea31';
}
.cs-icon-font-mdi-logout:before {
  content: '\ea32';
}
.cs-icon-font-mdi-magnify:before {
  content: '\ea33';
}
.cs-icon-font-mdi-menu:before {
  content: '\ea34';
}
.cs-icon-font-mdi-microsoft-excel:before {
  content: '\ea35';
}
.cs-icon-font-mdi-nodejs:before {
  content: '\ea36';
}
.cs-icon-font-mdi-note-check:before {
  content: '\ea37';
}
.cs-icon-font-mdi-note-edit:before {
  content: '\ea38';
}
.cs-icon-font-mdi-note-minus:before {
  content: '\ea39';
}
.cs-icon-font-mdi-note-search:before {
  content: '\ea3a';
}
.cs-icon-font-mdi-note:before {
  content: '\ea3b';
}
.cs-icon-font-mdi-pencil-box:before {
  content: '\ea3c';
}
.cs-icon-font-mdi-pencil-outline:before {
  content: '\ea3d';
}
.cs-icon-font-mdi-pencil:before {
  content: '\ea3e';
}
.cs-icon-font-mdi-plus:before {
  content: '\ea3f';
}
.cs-icon-font-mdi-progress-clock:before {
  content: '\ea40';
}
.cs-icon-font-mdi-relation-many-to-one:before {
  content: '\ea41';
}
.cs-icon-font-mdi-shape:before {
  content: '\ea42';
}
.cs-icon-font-mdi-subdirectory-arrow-right:before {
  content: '\ea43';
}
.cs-icon-font-mdi-swap-horizontal:before {
  content: '\ea44';
}
.cs-icon-font-mdi-swap-vertical:before {
  content: '\ea45';
}
.cs-icon-font-mdi-timer-sand:before {
  content: '\ea46';
}
.cs-icon-font-mdi-undo:before {
  content: '\ea47';
}
.cs-icon-font-mdi-upload:before {
  content: '\ea48';
}
.cs-icon-font-mdi-view-dashboard:before {
  content: '\ea49';
}
.cs-icon-font-mdi-wallet:before {
  content: '\ea4a';
}
.cs-icon-font-mdi-water:before {
  content: '\ea4b';
}

$cs-icon-font-mdi-account-circle: '\ea01';
$cs-icon-font-mdi-account-plus: '\ea02';
$cs-icon-font-mdi-account-supervisor: '\ea03';
$cs-icon-font-mdi-account-tie-hat: '\ea04';
$cs-icon-font-mdi-account: '\ea05';
$cs-icon-font-mdi-alert-circle-outline: '\ea06';
$cs-icon-font-mdi-alert: '\ea07';
$cs-icon-font-mdi-alpha-tbox-outline: '\ea08';
$cs-icon-font-mdi-arrow-down-circle: '\ea09';
$cs-icon-font-mdi-arrow-down: '\ea0a';
$cs-icon-font-mdi-arrow-left: '\ea0b';
$cs-icon-font-mdi-arrow-right: '\ea0c';
$cs-icon-font-mdi-arrow-up-circle: '\ea0d';
$cs-icon-font-mdi-arrow-up: '\ea0e';
$cs-icon-font-mdi-badge-account: '\ea0f';
$cs-icon-font-mdi-bell: '\ea10';
$cs-icon-font-mdi-calendar-range: '\ea11';
$cs-icon-font-mdi-calendar: '\ea12';
$cs-icon-font-mdi-camera: '\ea13';
$cs-icon-font-mdi-cancel: '\ea14';
$cs-icon-font-mdi-check-circle-outline: '\ea15';
$cs-icon-font-mdi-check-circle: '\ea16';
$cs-icon-font-mdi-check: '\ea17';
$cs-icon-font-mdi-clock-outline: '\ea18';
$cs-icon-font-mdi-close: '\ea19';
$cs-icon-font-mdi-cloud-upload-outline: '\ea1a';
$cs-icon-font-mdi-cog: '\ea1b';
$cs-icon-font-mdi-compass: '\ea1c';
$cs-icon-font-mdi-content-copy: '\ea1d';
$cs-icon-font-mdi-content-save-alert: '\ea1e';
$cs-icon-font-mdi-content-save-check: '\ea1f';
$cs-icon-font-mdi-content-save-edit: '\ea20';
$cs-icon-font-mdi-content-save: '\ea21';
$cs-icon-font-mdi-crown: '\ea22';
$cs-icon-font-mdi-delete: '\ea23';
$cs-icon-font-mdi-download-outline: '\ea24';
$cs-icon-font-mdi-email: '\ea25';
$cs-icon-font-mdi-eye: '\ea26';
$cs-icon-font-mdi-file-document-check-outline: '\ea27';
$cs-icon-font-mdi-file-eye-outline: '\ea28';
$cs-icon-font-mdi-file-import-outline: '\ea29';
$cs-icon-font-mdi-file-outline: '\ea2a';
$cs-icon-font-mdi-filter-outline: '\ea2b';
$cs-icon-font-mdi-filter: '\ea2c';
$cs-icon-font-mdi-flag: '\ea2d';
$cs-icon-font-mdi-folder-plus: '\ea2e';
$cs-icon-font-mdi-folder: '\ea2f';
$cs-icon-font-mdi-form-textbox: '\ea30';
$cs-icon-font-mdi-information: '\ea31';
$cs-icon-font-mdi-logout: '\ea32';
$cs-icon-font-mdi-magnify: '\ea33';
$cs-icon-font-mdi-menu: '\ea34';
$cs-icon-font-mdi-microsoft-excel: '\ea35';
$cs-icon-font-mdi-nodejs: '\ea36';
$cs-icon-font-mdi-note-check: '\ea37';
$cs-icon-font-mdi-note-edit: '\ea38';
$cs-icon-font-mdi-note-minus: '\ea39';
$cs-icon-font-mdi-note-search: '\ea3a';
$cs-icon-font-mdi-note: '\ea3b';
$cs-icon-font-mdi-pencil-box: '\ea3c';
$cs-icon-font-mdi-pencil-outline: '\ea3d';
$cs-icon-font-mdi-pencil: '\ea3e';
$cs-icon-font-mdi-plus: '\ea3f';
$cs-icon-font-mdi-progress-clock: '\ea40';
$cs-icon-font-mdi-relation-many-to-one: '\ea41';
$cs-icon-font-mdi-shape: '\ea42';
$cs-icon-font-mdi-subdirectory-arrow-right: '\ea43';
$cs-icon-font-mdi-swap-horizontal: '\ea44';
$cs-icon-font-mdi-swap-vertical: '\ea45';
$cs-icon-font-mdi-timer-sand: '\ea46';
$cs-icon-font-mdi-undo: '\ea47';
$cs-icon-font-mdi-upload: '\ea48';
$cs-icon-font-mdi-view-dashboard: '\ea49';
$cs-icon-font-mdi-wallet: '\ea4a';
$cs-icon-font-mdi-water: '\ea4b';
