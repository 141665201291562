$breakpoint: 992px !default;
$scale: 14px !default;

@import './_fonts';
@import './_animation';

// @import './_layout_dark';
@import './_main';
@import './_sidebar_vertical';
//@import './_sidebar_slim';
//@import './_sidebar_slim_plus';
//@import './_sidebar_horizontal';
@import './_sidebar_reveal';
//@import './_sidebar_drawer';
@import './menutheme/_menutheme';
@import './_responsive';
@import './_topbar';
@import './_breadcrumb';
//@import './_profile';
//@import './_config';
@import './_content';
@import './_typography';
@import './_utils';

@import './_layout_light';
@import './_layout_dim';
