@mixin cs-scroll(
  $widthHeight: 8px,
  $thumbBorderRadius: 10px,
  $overflow: true,
  $overlay: false,
  /*
  $RTHUMB: 87,
  $GTHUMB: 169,
  $BTHUMB: 196,
  */ $RTHUMB: 0,
  $GTHUMB: 0,
  $BTHUMB: 0,
  /*
  $RTHUMBHOVER: 87,
  $GTHUMBHOVER: 169,
  $BTHUMBHOVER: 196,
  */ $RTHUMBHOVER: 0,
  $GTHUMBHOVER: 0,
  $BTHUMBHOVER: 0,
  $opacityBlur: 0.3,
  $opacityHover: 0.5
) {
  @if $overflow == true {
    overflow: auto;
  }

  @if $overlay == true {
    overflow: clip !important;
  }

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: $widthHeight;
    width: $widthHeight;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba($RTRACK, $GTRACK, $BTRACK, 0.45) !important;
    -webkit-box-shadow: none;
    opacity: 0;
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: rgba($RTHUMB, $GTHUMB, $BTHUMB, $opacityBlur);
    opacity: $opacityBlur;
    //outline: 1px solid rgba($RTHUMB, $GTHUMB, $BTHUMB, 1);
    border-radius: $thumbBorderRadius;

    /* Handle on hover */
    &:hover {
      opacity: $opacityHover !important;
      background-color: rgba($RTHUMBHOVER, $GTHUMBHOVER, $BTHUMBHOVER, $opacityHover);
    }
  }

  /* Optional: scrollbar corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Transparent like macOS */
  }
}
