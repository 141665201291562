/* poppins-regular - Latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('../../fonts/poppins-regular.woff2') format('woff2'),
    url('../../fonts/poppins-regular.woff') format('woff');
}

/* poppins-medium - Latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('../../fonts/poppins-medium.woff2') format('woff2'),
    url('../../fonts/poppins-medium.woff') format('woff');
}

/* poppins-semibold - Latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('../../fonts/poppins-semibold.woff2') format('woff2'),
    url('../../fonts/poppins-semibold.woff') format('woff');
}

/* poppins-bold - Latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('../../fonts/poppins-bold.woff2') format('woff2'),
    url('../../fonts/poppins-bold.woff') format('woff');
}

@font-face {
  font-family: 'flexmonster-icons';
  src:
    local(''),
    url('../../fonts/flexmonster-icons.woff') format('woff'),
    url('../../fonts/flexmonster-icons.ttf') format('truetype'),
    url('../../fonts/flexmonster-icons.svg#flexmonster-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
